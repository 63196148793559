.Navigation__background {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: fixed;
    top: 3rem;
    right: 3rem;
    /* background-image: radial-gradient(var(--color-primary), var(--color-secondary)); */
    background: var(--text-secondary);
    z-index: 100;
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
}

.Navigation__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
