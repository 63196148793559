.Section-contact {
    padding: 25rem 0;
    background-color: var(--text-secondary);
    clip-path: polygon(0 25vh, 100% 0, 100% 100%, 0 100%);
    border-bottom: .6rem solid #f17017;
}

@media (max-width: 600px) {
    .Section-contact {
        clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
    }
}

.Contact__mail {
    color: var(--text-primary);
    font-size: 3rem;
    padding: 1rem 2rem;
    display: inline-block;
    border: 1px solid var(--text-primary);
    margin: 0 auto 10rem auto;
}

@media (max-width: 600px) {
    .Contact__mail {
        font-size: 2.5rem;
    }
}

.Contact__social {
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    margin: auto;
}

@media (max-width: 600px) {
    .Contact__social {
        width: 80%;
    }
}

.Contact__icon {
    color: var(--text-primary);
    font-size: 6rem;
    border-radius: 50%;
}

.Madsemicolon {
    margin: 12rem auto 0 auto;
    width: 50rem;
}

.Madsemicolon img{
    width: 100%;
}