.Section-about {
    padding: 25rem 0 20rem 0;
    margin-top: -13rem;
    background-color: var(--text-primary);
}

.About__text {
    width: 60%;
    text-align: center;
    font-size: 2rem;
    margin: 0 auto 4rem auto;
    letter-spacing: .1rem;
}

@media (max-width: 600px) {
    .About__text {
        width: 90%;
    }
}

.About__strong {
    padding: 1rem 2rem;
    display: inline-block;
    border: .2rem solid var(--color-tertiary);
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--color-tertiary);
    font-style: italic;
}