.Section-work {
    padding: 20rem 0;
    background: #ead7e065;
}

.Work-container {
    width: 80%;
    margin: auto;
}

.Work__strong {
    margin-top: 9rem;
    padding: 1rem 2rem;
    display: inline-block;
    border: .2rem solid var(--color-tertiary);
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--color-tertiary);
    font-style: italic;
}