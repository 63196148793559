.Skill-list__item {
    margin: 0 2.5rem 6rem 2.5rem;
    padding: 1.5rem;
    box-shadow: -1rem 1rem 2rem rgba(0, 0, 0, .05), 1rem 1rem 2rem rgba(0, 0, 0, .07);
    border-radius: 50%;
    transition: .3s ease-in-out;
}

.Skill-list__item:hover {
    transform: translateY(-1.1rem) scale(1.05);
    box-shadow: 1rem 1rem 4rem rgba(0, 0, 0, .2);
}

.Skill-icon {
    font-size: 7rem;
}