:root {
  --color-primary: #443a8b;
  --color-secondary: #2391ee;
  --color-tertiary: #f05688;
  --color-quaternary: #50eecb;

  --bg-primary: #f7f7f7;

  --text-primary: #fff;
  --text-secondary: #2f2f2f;
  
}

/* Utility classes */
.u-tc {
  text-align: center;
}

.u-mb-sm {
  margin-bottom: 1.5rem;
}

.u-mb-md {
  margin-bottom: 4rem;
}

.u-mb-lg {
  margin-bottom: 8rem;
}
/* Utility classes */


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  html {
    font-size: 45%;
  }
}

body {
  overflow-x: hidden;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.7;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
