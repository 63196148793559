.Navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    list-style: none;
    text-align: center;
    width: 100%;
}

