.Button {
    padding: .9rem 2rem;
    display: inline-block;
    border-radius: .2rem;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: .1rem;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 1.2rem;
    text-decoration: none;
}

@media (max-width: 768px) {
    .Button {
        font-size: 1.8rem;
    }
}

.Button--primary {
    color: var(--color-primary);
    background-color: rgba(67, 58, 139, 0.118);
}

.Button--tertiary{
    color: var(--color-tertiary);
    background-color: rgba(240, 86, 135, 0.118)
}

