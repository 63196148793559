.Blog__Item {
    margin: 4rem;
}

/*
@media (max-width: 768px) {
    .Blog__Item {
        margin: 0 4rem 4rem 4rem;
    }
} */

.ImgBox {
    margin-bottom: 1rem;
    border-radius: .3rem;
    overflow: hidden;
}

.ImgBox__Img {
    width: 100%;
    transition: .2s all ease-in-out;
}

.Item__Head {
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.Item__tags {
    display: block;
    font-size: 1.3rem;
    color: grey;
    margin-bottom: 2rem;
}

.Item__tag-icon {
    margin-right: .5rem;
}

.Blog-item__btn-icon {
    margin-right: .8rem;
}