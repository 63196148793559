.Skill-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 60%;
    padding: 0;
    margin: 0 auto;
    position: relative;
}

@media (max-width: 600px) {
    .Skill-list {
        max-width: 80%;
    }
}
